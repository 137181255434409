import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as unitConversion from 'services/unitConversion'

import SectionButton from './SectionButton'
import Table from 'components/stateless/tables/Table'
import Modal from 'components/stateless/modals/Modal'
import QRCode from 'qrcode.react'

import copy from 'copy-to-clipboard'

import style from './Wallet.scss'
import moneyImg from '/../assets/imgs/money.png'
import sendImg from '/../assets/imgs/send.png'

import sendMoney from 'actions/wallet/sendMoney'
import requestTransactions from 'actions/wallet/requestTransactions'
import BlueButton from 'components/stateless/buttons/BlueButton'

import { NamespacesConsumer } from 'react-i18next'

class Wallet extends Component {
  constructor(props, context) {
    super(props)
    this.dispatch = props.dispatch
    this.state = {
      addressModal: false,
      sendModal: false
    }
  }

  componentDidMount() {
    this.dispatch(requestTransactions())
  }

  toggleAddressModal = () => {
    this.setState(prevState => ({ addressModal: !prevState.addressModal }))
  }

  toggleSendModal = () => {
    if (this.sendAddress && this.sendAmount) {
      this.sendAddress.value = ''
      this.sendAmount.value = ''
    }
    this.setState(prevState => ({ sendModal: !prevState.sendModal }))
  }

  copyAddress = () => {
    copy(this.props.wallet.address)
  }

  send = () => {
    this.dispatch(sendMoney(this.sendAddress.value, unitConversion.toWei(this.sendAmount.value)))
    this.toggleSendModal()
  }

  buildTransactionRows = (t) => {
    const rows = []
    if (this.props.wallet.transactions) {
      for (const transaction of this.props.wallet.transactions) {
        let amount = unitConversion.toEth(transaction.amount)
        if (transaction.from === this.props.wallet.address) {
          amount = `- ${amount}`
        }
        let from = transaction.from
        let to = transaction.to
        if (transaction.from === this.props.wallet.address) {
          from = t('YOUR_WALLET')
        } else if (this.props.relatedUsers[transaction.from]) {
          from = this.props.relatedUsers[transaction.from].name
        }
        if (transaction.to === this.props.wallet.address) {
          to = t('YOUR_WALLET')
        } else if (this.props.relatedUsers[transaction.to]) {
          to = this.props.relatedUsers[transaction.to].name
        }
        rows.push([
          { id: 0, value: from },
          { id: 1, value: to },
          { id: 2, value: amount },
          { id: 3, value: new Date(transaction.timestamp * 1000).toLocaleString() }
        ])
      }
    }
    return rows
  }

  render() {
    return (
      <NamespacesConsumer ns={['wallet', 'common']}>
        {t => {
          const columns = [
            { name: t('FROM'), alignment: 'left', style: { minWidth: '300px' } },
            { name: t('TO'), alignment: 'left', style: { minWidth: '300px' } },
            { name: t('AMOUNT'), alignment: 'center' },
            { name: t('DATE'), alignment: 'center' }
          ]
          return (
            <main>
              <div className={style['buttons-wrapper']}>
                <SectionButton image={moneyImg} text={t('RECEIVE')} onClick={this.toggleAddressModal} />
                <SectionButton image={sendImg} text={t('SEND')} onClick={this.toggleSendModal} />
              </div>
              <Table title={t('TRANSACTIONS')} loading={this.props.wallet.fetchingTransactions} columns={columns} rows={this.buildTransactionRows(t)} />
              <Modal title={t('WALLET_ADDRESS')} shouldShow={this.state.addressModal} closeAction={this.toggleAddressModal}>
                <div className={style['modal-content']}>
                  <QRCode className={style.qr} value={this.props.wallet.address} renderAs='svg' size={400} />
                  <div> {this.props.wallet.address} </div>
                  <div className={style['button-wrapper']}>
                    <BlueButton onClick={this.copyAddress} > {t('COPY_ADDRESS')} </BlueButton>
                  </div>
                </div>
              </Modal>
              <Modal title={t('SEND')} shouldShow={this.state.sendModal} closeAction={this.toggleSendModal}>
                <div className={style['modal-content']}>
                  <label htmlFor='address'> {t('ADDRESS')} </label>
                  <input id='address' ref={input => { this.sendAddress = input }} />
                  <label htmlFor='amount'> {t('AMOUNT')} </label>
                  <div className={style['amount-wrapper']}>
                    <input type='number' id='amount' ref={input => { this.sendAmount = input }} /> <span> ETH </span>
                  </div>
                  <div className={style['button-wrapper']}>
                    <BlueButton onClick={this.send}>{t('common:SUBMIT')}</BlueButton>
                  </div>
                </div>
              </Modal>
            </main>
          )
        }}
      </NamespacesConsumer>
    )
  }
}
export default connect(state => ({ relatedUsers: state.user.relatedUsers, wallet: state.wallet }))(Wallet)
