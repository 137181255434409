import switchcase from './switchCase'

export default (
  state = {
    fetching: false,
    deleting: [],
    entries: null
  },
  action
) => switchcase({
  'REQUEST_HEIRS': () => Object.assign({}, state, { fetching: true }),
  'HEIRS_FETCH_DONE': () => Object.assign({}, state, { fetching: false, entries: action.heirs }),
  'DELETE_HEIR': () => Object.assign({}, state, { deleting: [...state.deleting, action.account] }),
  'DELETE_HEIR_DONE': () => Object.assign({}, state, { deleting: state.deleting.filter(account => action.account !== account) })
})(state)(action.type)
