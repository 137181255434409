import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import witnessesFetchDone from 'actions/witnesses/witnessesFetchDone'
import requestWitnesses from 'actions/witnesses/requestWitnesses'
import deleteWitnessDone from 'actions/witnesses/deleteWitnessDone'
import { actions as toastrActions } from 'react-redux-toastr'

export default ({ drizzle,firebase,i18n }) => {
  function * fetchWitnesses () {
    try {
      const witnessCount = yield call(drizzle.contracts.Wallet.methods.getWitnessCount().call)
      const witnesses = []
      for (let i = 0; i < witnessCount; i++) {
        const witness = yield call(drizzle.contracts.Wallet.methods.getWitness(i).call)
        witnesses.push({
          address: witness[0],
          confirmed: witness[1]
        })
      }
      yield put(witnessesFetchDone(witnesses))
    } catch (error) {
      yield put(witnessesFetchDone(null))
      console.log(error)
    }
  }

  function * addWitness (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      if (action.witness.email) {
        firebase.firestore()
          .collection('invites')
          .doc(drizzle.contracts.Wallet.address)
          .collection('accounts')
          .doc(action.witness.account)
          .set({ email: action.witness.email })
      }
      yield call(drizzle.contracts.Wallet.methods.newWitness(action.witness.account).send)
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('witnesses:WITNESS_ADDED_SUCCESSFULLY_TITLE'),
        message: i18n.t('witnesses:WITNESS_ADDED_SUCCESSFULLY_MESSAGE')
      }))
      yield put(requestWitnesses(drizzle))
    } catch (error) {
      console.log(error)
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('witnesses:WITNESS_ADDED_WITHERROR_TITLE'),
        message: i18n.t('witnesses:WITNESS_ADDED_WITHERROR_MESSAGE')
      }))
    }
  }

  function * setRequiredConfirmations (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      yield call(drizzle.contracts.Wallet.methods.setRequiredConfirmations.cacheSend, action.requiredConfirmations)
    } catch (error) {
      console.log(error)
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('witnesses:SET_REQUIRED_CONFIRMATIONS_WITHERROR_TITLE'),
        message: i18n.t('witnesses:SET_REQUIRED_CONFIRMATIONS_WITHERROR_MESSAGE')
      }))
    }
  }

  function * deleteWitness (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      yield call(drizzle.contracts.Wallet.methods.deleteWitness(action.account).send)
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('witnesses:WITNESS_REMOVED_SUCCESSFULLY_TITLE'),
        message: i18n.t('witnesses:WITNESS_REMOVED_SUCCESSFULLY_MESSAGE')
      }))
      yield put(requestWitnesses(drizzle))
      yield put(deleteWitnessDone(action.account))
    } catch (error) {
      yield put(deleteWitnessDone(action.account))
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('witnesses:WITNESS_REMOVED_WITHERROR_TITLE'),
        message: i18n.t('witnesses:WITNESS_REMOVED_WITHERROR_MESSAGE')
      }))
    }
  }
  return function * watch () {
    yield takeLatest('REQUEST_WITNESSES', fetchWitnesses)
    yield takeEvery('ADD_WITNESS', addWitness)
    yield takeEvery('DELETE_WITNESS', deleteWitness)
    yield takeEvery('SET_REQUIRED_CONFIRMATIONS', setRequiredConfirmations)
  }
}
