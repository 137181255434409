import switchcase from './switchCase'

export default (
  state = {
    fetchingLegacy: false,
    fetchingTestimonial: false,
    legacy: null,
    testimonial: null,
    testifying: null
  },
  action
) => switchcase({
  'REQUEST_LEGACY': () => Object.assign({}, state, { fetchingLegacy: true }),
  'LEGACY_FETCH_DONE': () => Object.assign({}, state, { fetchingLegacy: false, legacy: action.legacy }),
  'TESTIMONIAL_FETCH_DONE': () => Object.assign({}, state, { fetchingTestimonial: false, testimonial: action.testimonial }),
  'REQUEST_TESTIFY': () => Object.assign({}, state, { testifying: action.wallet }),
  'TESTIFY_DONE': () => Object.assign({}, state, { testifying: null }),
  'REQUEST_CLAIM': () => Object.assign({}, state, { claiming: action.wallet }),
  'CLAIM_DONE': () => Object.assign({}, state, { claiming: null })
})(state)(action.type)
