import React from 'react'
import style from './Style'
import classNames from 'classnames'

export default (props) => {
  let className = classNames(style.orange, props.className)
  if (props.bigger) {
    className = classNames(style.orange, props.className,style.bigger)
  }
  return (
    <button {...props} className={className}>
      {props.children}
    </button>
  )
}