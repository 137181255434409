import { call, put, takeLatest } from 'redux-saga/effects'
import initializeDone from 'actions/wallet/initializeDone'
import { actions as toastrActions } from 'react-redux-toastr'
import getWalletContract from 'services/getWalletContract'

export default ({ drizzle, i18n }) => {
  function addContract (address) {
    const contractConfig = {
      contractName: 'Wallet',
      web3Contract: getWalletContract(drizzle, address)
    }
    const events = []
    return ({ 'type': 'ADD_CONTRACT', contractConfig, events, drizzle })
  }

  function * initializeWalletContract () {
    try {
      const address = yield call(drizzle.contracts.Index.methods.getWallet().call)
      let momentOfDeath = 0
      let gracePeriod = 0
      if (address > 0) {
        yield put(addContract(address))
        const walletDetails = yield call(drizzle.contracts.WalletProxy.methods.details(address).call)
        momentOfDeath = parseInt(walletDetails.momentOfDeath)
        gracePeriod = parseInt(walletDetails.gracePeriod)
      }
      yield put(initializeDone(address, momentOfDeath, gracePeriod))
    } catch (error) {
      console.log(error)
    }
  }

  function * newWallet () {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      yield call(drizzle.contracts.Index.methods.newWallet().send)
      const address = yield call(drizzle.contracts.Index.methods.getWallet().call)
      yield put(addContract(address))
      yield put(initializeDone(address))
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('wallet:WALLET_CREATED_SUCCESSFULLY_TITLE'),
        message: i18n.t('wallet:WALLET_CREATED_SUCCESSFULLY_MESSAGE'),
      }))
    } catch (error) {
      yield put(initializeDone(0))
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('wallet:WALLET_CREATED_WITHERROR_TITLE'),
        message: i18n.t('wallet:WALLET_CREATED_WITHERROR_MESSAGE'),
      }))
      console.log(error)
    }
  }
  return function * watch () {
    yield takeLatest('INITIALIZE_WALLET_CONTRACT', initializeWalletContract)
    yield takeLatest('REQUEST_NEW_WALLET', newWallet)
  }
}
