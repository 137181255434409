import React from 'react'
import { Route } from 'react-router-dom'
import Layout from 'components/router/layout/Layout'
export default ({ wallet, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    <Layout dapp {...props}>
      <Component {...props}/>
    </Layout>
  )} />
)
