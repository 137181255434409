import React from 'react'
import style from './Style'
import classNames from 'classnames'

export default (props) => {
  const className = classNames(style.blue,props.className)
  return (
    <button {...props} className={className}>
      {props.children}
    </button>
  )
}