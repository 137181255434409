import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Layout from 'components/router/layout/Layout'

const WalletRoute = ({ wallet, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (wallet.address > 0) {
      return (
        <Layout dapp {...props}>
          <Component {...props} />
        </Layout>
      )
    } else {
      return <Redirect to='/' />
    }
  }} />
)
export default connect(state => ({ wallet: state.wallet }))(WalletRoute)
