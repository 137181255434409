import React, { Component } from 'react'
import { connect } from 'react-redux'
import style from './Navbar.scss'
import * as unitConversion from 'services/unitConversion'
import { withDrizzle } from 'services/Context'
import { NamespacesConsumer } from 'react-i18next'

class WalletBalance extends Component {

  constructor({ drizzle }) {
    super()
    this.balanceKey = drizzle.contracts.Wallet.methods.balance.cacheCall()
  }

  render() {
    return (
      <NamespacesConsumer ns={['layout']}>
        {t => {
          let balance
          if (this.props.wallet.balance[this.balanceKey]) {
            balance = this.props.wallet.balance[this.balanceKey].value
            balance = `${unitConversion.toEth(balance)} ETH`
          } else {
            balance = '🔄'
          }
          return (
            <div className={style['balance-box']}>
              {t('CURRENT_BALANCE')}<span> {balance} </span>
            </div>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ wallet: state.contracts.Wallet }))(withDrizzle(WalletBalance))
