import switchcase from './switchCase'

export default (
  state = {
    fetching: false,
    deleting: [],
    entries: null
  },
  action
) => switchcase({
  'REQUEST_WITNESSES': () => Object.assign({}, state, { fetching: true }),
  'WITNESSES_FETCH_DONE': () => Object.assign({}, state, { fetching: false, entries: action.witnesses }),
  'DELETE_WITNESS': () => Object.assign({}, state, { deleting: [...state.deleting, action.account] }),
  'DELETE_WITNESS_DONE': () => Object.assign({}, state, { deleting: state.deleting.filter(account => account !== action.account) })

})(state)(action.type)
