import React, { Component } from 'react'
import style from './Welcome.scss'
import Layout from 'components/router/layout/Layout'
import { NamespacesConsumer } from 'react-i18next'
import { withFirebase } from 'services/Context'
import OrangeButton from 'components/stateless/buttons/OrangeButton'
import BlueButton from 'components/stateless/buttons/BlueButton'

class Welcome extends Component {
  constructor() {
    super()
    this.state = { tutorial: [], selectedIndex: 0 }
  }

  componentDidMount() {
    const firestore = this.props.firebase.firestore()
    firestore.collection('misc').doc('front').get().then((doc) => {
      this.setState({ tutorial: doc.data().tutorial })
    }).catch(console.error)

  }

  render() {
    const tutorialSteps = this.state.tutorial
    return (
      <NamespacesConsumer ns={['welcome']}>
        {t => {
          return (
            <Layout>
              <div className={style.header}>
                <h1>{t('GET_STARTED')}</h1>
              </div>
              {tutorialSteps.length > 0 &&
                <React.Fragment>
                  <div className={style.carousel}>
                    <div className={style.items}>
                      {tutorialSteps.map((step, index) => {
                        if (step.type === 'image') {
                          return (
                            <div key={index} className={this.state.selectedIndex === index ? style.selected : ''}>
                              <img src={step.value} />
                            </div>
                          )
                        } else if (step.type === 'iframe') {
                          return (
                            <div className={this.state.selectedIndex === index ? style.selected : ''} key={index} dangerouslySetInnerHTML={{ __html: step.value }} />
                          )
                        }
                      })}
                    </div>
                    <div className={style.controls}>
                      <BlueButton
                        className={this.state.selectedIndex === 0 ? style.hidden : ''}
                        onClick={() => {
                          this.setState({ selectedIndex: this.state.selectedIndex - 1 })
                        }}
                      >
                        {t('PREVIOUS')}
                      </BlueButton>
                      <BlueButton
                        className={this.state.selectedIndex === tutorialSteps.length - 1 ? style.hidden : ''}
                        onClick={() => {
                          this.setState({ selectedIndex: this.state.selectedIndex  + 1 })
                        }}
                      >
                        {t('NEXT')}
                      </BlueButton>
                    </div>
                  </div>
                  <div className={style.footer}>
                  <OrangeButton
                    bigger={1}
                    onClick={() => {
                      window.location.href = '/dapp.html'
                    }}
                  >{t('START_DAPP')}</OrangeButton>
                  </div>
                </React.Fragment>
              }
            </Layout>
          )
        }}
      </NamespacesConsumer>

    )
  }
}


export default withFirebase(Welcome)