import React from 'react'
import Header from 'components/router/layout/header/Header'
import Footer from 'components/router/layout/footer/Footer'
import RipModal from 'components/wallet/RipModal'
import style from '../Router.scss'
import { withFirebase } from 'services/Context'

class Layout extends React.Component {

  constructor() {
    super()
    this.state = { front: {} }
  }

  componentDidMount() {
    const firestore = this.props.firebase.firestore()
    firestore.collection('misc').doc('front').get().then((doc) => {
      this.setState({ front: doc.data() })
    }).catch(console.error)

  }

  render() {
    return (
      <div className={style.wrapper}>
        {this.props.dapp &&
          <RipModal />
        }
        <Header {...this.props} />
        <div className={style.main}>
          {this.props.children}
        </div>
        <Footer front={this.state.front} />
      </div>
    )
  }
}
export default withFirebase(Layout)