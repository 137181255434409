import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { actions as toastrActions } from 'react-redux-toastr'
import getWalletContract from 'services/getWalletContract'
import transactionFetchDone from 'actions/wallet/transactionFetchDone'
import requestTransactions from 'actions/wallet/requestTransactions'
import resetRipDone from 'actions/wallet/resetRipDone'

export default ({ drizzle, i18n }) => {
  function * sendMoney (action) {
    try {
      const walletMethods = drizzle.contracts.Wallet.methods
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      yield call(walletMethods.doSend(action.recipient, action.amount).send)
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('common:TRANSACTION_CONFIRMED_TITLE'),
        message: i18n.t('common:TRANSACTION_CONFIRMED_MESSAGE')
      }))
      yield put(requestTransactions())
    } catch (e) {
      console.log(e)
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('common:TRANSACTION_ERROR_TITLE'),
        message: i18n.t('common:TRANSACTION_ERROR_MESSAGE')
      }))
    }
  }

  function * resetRip () {
    try {
      const walletMethods = drizzle.contracts.Wallet.methods
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      yield call(walletMethods.resetRip().send)
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('wallet:RIP_EVENT_CANCELLED_SUCCESSFULLY_TITLE'),
        title: i18n.t('wallet:RIP_EVENT_CANCELLED_SUCCESSFULLY_MESSAGE'),
      }))
      yield put(resetRipDone(true))
    } catch (e) {
      console.log(e)
      yield put(resetRipDone(false))
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('wallet:RIP_EVENT_CANCELLED_WITHERROR_TITLE'),
        title: i18n.t('wallet:RIP_EVENT_CANCELLED_WITHERROR_MESSAGE'),
      }))
    }
  }

  function * fetchTransactions (action) {
    try {
      const wallet = getWalletContract(drizzle, drizzle.contracts.Wallet.address)
      const transactionEvents = yield call([wallet, wallet.getPastEvents], 'TransactionEvent', { fromBlock: 0, toBlock: 'latest' })
      const transactions = []
      for (const event of transactionEvents) {
        const block = yield call(drizzle.web3.eth.getBlock, event.blockNumber)
        transactions.push({
          from: event.returnValues.from,
          to: event.returnValues.to,
          amount: event.returnValues.amount,
          timestamp: block.timestamp
        })
      }
      yield put(transactionFetchDone(transactions))
    } catch (e) {
      yield put(transactionFetchDone(null))
      console.log(e)
    }
  }
  return function * watch () {
    yield takeEvery('REQUEST_SEND_MONEY', sendMoney)
    yield takeLatest('REQUEST_TRANSACTIONS', fetchTransactions)
    yield takeLatest('RESET_RIP', resetRip)
  }
}
