import React from 'react'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import WalletRoute from 'components/router/WalletRoute'
import LayoutedRoute from 'components/router/LayoutedRoute'
import Home from 'components/home/Home'
import Wallet from 'components/wallet/Wallet'
import Witnesses from 'components/witnesses/Witnesses'
import Heirs from 'components/heirs/Heirs'
import Login from 'components/login/Login.jsx'
import login from 'actions/user/login'
import logout from 'actions/user/logout'
import { connect } from 'react-redux'
import { withFirebase } from 'services/Context'
import putRelatedUsers from 'actions/user/putRelatedUsers'

class Router extends React.Component {

  componentDidMount() {
    const isAccountInUse = this.props.firebase.functions().httpsCallable('isAccountAlreadyInUseBySomeoneElse')
    window.ethereum.on('accountsChanged', (accounts) => {
      location.reload(true)
    })
    window.ethereum.on('networkChanged', (netId) => {
      location.reload(true)
    })
    this.unregisterAuthWatcher = this.props.firebase.auth().onAuthStateChanged(user => {
      if (user !== null) {
        if (this.props.accounts[0]) {
          isAccountInUse({ account: this.props.accounts[0] }).then((result) => {
            if (result.data.inUse) {
              this.props.firebase.auth().signOut()
              alert('THE ACCOUNT IS ALREADY LINKED TO SOMEONE ELSE')
            } else {
              this.props.dispatch(login(user))
              this.props.firebase.firestore().collection('users').doc(user.uid).set(
                {
                  accounts: this.props.firebase.firestore.FieldValue.arrayUnion(this.props.accounts[0]),
                  name: user.displayName,
                  email: user.email,
                  phone: user.phoneNumber,
                  photo: user.photoURL,
                  emailVerified: user.emailVerified
                },
                { merge: true }
              ).then(() => {
                this.unregisterQueryResultWatcher = this.props.firebase.firestore()
                  .collection('users')
                  .where('related_accounts', 'array-contains', this.props.accounts[0])
                  .onSnapshot(
                    querySnapshot => {
                      const userMap = {}
                      for (const user of querySnapshot.docs) {
                        const userData = user.data()
                        if (userData.accounts) {
                          for (const account of userData.accounts) {
                            userMap[account] = userData
                          }
                        }
                        if (userData.wallets) {
                          for (const wallet of userData.wallets) {
                            userMap[wallet] = userData
                          }
                        }
                      }
                      this.props.dispatch(putRelatedUsers(userMap))
                    },
                    console.log
                  )

              })
            }
          })
        }
      } else {
        this.props.dispatch(logout())
        if (this.unregisterQueryResultWatcher) {
          this.unregisterQueryResultWatcher()
        }
      }
    })

  }

  componentWillUnmount() {
    this.unregisterAuthWatcher()
    if (this.unregisterQueryResultWatcher) {
      this.unregisterQueryResultWatcher()
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <LayoutedRoute exact path='/' component={Home} />
          <Route exact path='/login' component={Login} />
          <WalletRoute exact path='/wallet' component={Wallet} />
          <WalletRoute exact path='/witnesses' component={Witnesses} />
          <WalletRoute exact path='/heirs' component={Heirs} />
          <Redirect to='/' />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default connect(state => ({ accounts: state.accounts }))(withFirebase(Router))
