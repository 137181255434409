import React from 'react'

const FirebaseContext = React.createContext({})

export const FirebaseProvider = FirebaseContext.Provider
export const FirebaseConsumer = FirebaseContext.Consumer

const DrizzleContext = React.createContext({})

export const DrizzleProvider = DrizzleContext.Provider
export const DrizzleConsumer = DrizzleContext.Consumer

export const withFirebase = (WrappedComponent) => {
  const withHOC = (props) => {
    return (
      <FirebaseConsumer>
        {context => {
          return (<WrappedComponent {...props} firebase={context} />)
        }}

      </FirebaseConsumer>
    )
  }
  withHOC.WrappedComponent = WrappedComponent
  return withHOC
}

export const withDrizzle = (WrappedComponent) => {
  const withHOC = (props) => {
    return (
      <DrizzleConsumer>
        {context => <WrappedComponent {...props} drizzle={context} />}
      </DrizzleConsumer>
    )
  }
  withHOC.WrappedComponent = WrappedComponent
  return withHOC
}
