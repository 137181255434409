import switchcase from './switchCase'

export default (
  state = {
    user: null,
    relatedUsers: {}
  },
  action
) => switchcase({
  'LOGIN': () => Object.assign({}, state, { user: action.user }),
  'LOGOUT': () => Object.assign({}, state, { user: null }),
  'RELATED_USERS_FETCHED': () => Object.assign({}, state, { relatedUsers: action.relatedUsers })
})(state)(action.type)
