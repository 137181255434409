import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { withFirebase } from 'services/Context'

class Login extends React.Component {
  render() {
    const uiConfig = {
      signInFlow: 'redirect',
      signInOptions: [
        this.props.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        this.props.firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => false
      }
    }
    return (
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={this.props.firebase.auth()} />
    )
  }
}
export default withFirebase(Login)

