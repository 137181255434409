import React from 'react'
import Navbar from './navbar/Navbar'
import style from './Header.scss'
import logo from '/../assets/imgs/logo.png'
import OrangeButton from 'components/stateless/buttons/OrangeButton'
import loginWindow from 'services/loginWindow'
import { withFirebase } from 'services/Context'
import { connect } from 'react-redux'
import { NamespacesConsumer } from 'react-i18next'

const Header = ({ firebase, user, dapp }) => (
  <NamespacesConsumer ns={['layout']}>
    {t => (
      <div className={style.wrapper}>
        <div className={style.main}>
          <div className={style.fill}>
            <img role='presentation' src={logo} />
          </div>
          <div className={style.buttonContainer}>
            {!dapp &&
              <OrangeButton bigger={1} onClick={() => {
                window.location.href = '/dapp.html'
              }}>{t('START_DAPP')}</OrangeButton>
            }
            {dapp && user !== null &&
              <div className={style.authData}>
                <img src={user.photoURL} />
                <span> {user.displayName} </span>
                <span className={style.logout} onClick={() => {
                  firebase.auth().signOut()
                }}>{t('LOGOUT')}</span>
              </div>
            }
            {dapp && user === null &&
              <OrangeButton bigger={1} onClick={() => {
                loginWindow({ firebase })
              }}>{t('LOGIN')}</OrangeButton>
            }
          </div>
        </div>
        {dapp &&
          <Navbar />
        }
      </div>

    )}

  </NamespacesConsumer>
)

export default connect(state => ({
  user: state.user.user
}))(withFirebase(Header))
