import switchcase from './switchCase.js'

export default (
  state = {
    resettingRip: false,
    initialized: false,
    address: 0,
    momentOfDeath: 0,
    gracePeriod: 0,
    creating: false,
    fetchingTransactions: false,
    transactions: null
  },
  action
) => switchcase({
  'REQUEST_NEW_WALLET': () => Object.assign({}, state, { creating: true }),
  'INITIALIZE_WALLET_CONTRACT_DONE': () => Object.assign(
    {}, state, { initialized: true, address: action.address, creating: false, gracePeriod: action.gracePeriod, momentOfDeath: action.momentOfDeath }
  ),
  'REQUEST_TRANSACTIONS': () => Object.assign({}, state, { fetchingTransactions: true }),
  'TRANSACTION_FETCH_DONE': () => Object.assign({}, state, { fetchingTransactions: false, transactions: action.transactions }),
  'RESET_RIP': () => Object.assign({}, state, { resettingRip: true }),
  'RESET_RIP_DONE': () => Object.assign({}, state, { resettingRip: false, momentOfDeath: action.success ? 0 : state.momentOfDeath })
})(state)(action.type)
