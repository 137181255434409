import React, { Component } from 'react'
import { connect } from 'react-redux'
import Router from '/components/router/Router'
import initialize from 'actions/wallet/initialize'
import style from './Loader.scss'
import loadingImg from '/../assets/imgs/loading-dapp.png'
import { NamespacesConsumer } from 'react-i18next'

class LoadWallet extends Component {
  constructor({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  componentDidMount() {
    this.dispatch(initialize())
  }

  render() {
    if (this.props.wallet.initialized) {
      return (<Router />)
    }
    return (
      <NamespacesConsumer ns={['layout']}>
        {t => {
          return (
            <div className={style.wrapper}>
              <div className={style.container}>
                <img src={loadingImg} />
                <span>{t('LOADING_WALLET')}</span>
              </div>
            </div>
          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({
  wallet: state.wallet
}))(LoadWallet)
