import React from 'react'
import style from './Home.scss'
import { connect } from 'react-redux'
import * as unitConversion from 'services/unitConversion'
import requestLegacy from 'actions/visitor/requestLegacy'
import requestTestimonial from 'actions/visitor/requestTestimonial'
import requestTestify from 'actions/visitor/requestTestify'
import requestClaim from 'actions/visitor/requestClaim'
import Table from 'components/stateless/tables/Table'
import OrangeButton from 'components/stateless/buttons/OrangeButton'
import WhiteButton from 'components/stateless/buttons/WhiteButton'
import { NamespacesConsumer } from 'react-i18next'

class Home extends React.PureComponent {
  constructor({ dispatch }) {
    super()
    this.dispatch = dispatch
  }
  componentDidMount() {
    this.dispatch(requestLegacy())
    this.dispatch(requestTestimonial())
  }

  testify = (entry) => {
    this.dispatch(requestTestify(entry.wallet))
  }

  claim = (entry) => {
    this.dispatch(requestClaim(entry.wallet))
  }

  buildLegacyRows = (t) => {
    const rows = []

    if (this.props.visitor.legacy) {
      for (const entry of this.props.visitor.legacy) {
        const isClaiming = this.props.visitor.claiming === entry.wallet
        const title = entry.hasClaimed ? t('CLAIMED'): isClaiming ? t('CLAIMING') : t('CLAIM')
        const disabled = !entry.claimable || entry.hasClaimed || isClaiming
        let button
        if (entry.hasClaimed || isClaiming) {
          button = (
            <OrangeButton disabled={true}>
              {title}
            </OrangeButton>
          )
        } else {
          button = (
            <WhiteButton disabled={disabled} onClick={() => {
              this.claim(entry)
            }}>
              {title}
            </WhiteButton>
          )
        }
        const amount = unitConversion.toEth(entry.legacy)
        let displayName = entry.wallet
        if (this.props.relatedUsers[entry.wallet]) {
          displayName = this.props.relatedUsers[entry.wallet].name
        }
        rows.push([
          { id: 0, value: displayName },
          { id: 1, value: amount, extra: 'ETH' },
          { id: 2, value: (entry.momentOfDeath > 0 ? new Date(entry.momentOfDeath * 1000).toLocaleString() : '--') },
          { id: 3, value: entry.gracePeriod / 3600 + t('HOURS') },
          { id: 4, component: button }
        ])
      }
    }
    return rows
  }

  buildTestimonialRows = (t) => {
    const rows = []
    if (this.props.visitor.testimonial) {
      for (const entry of this.props.visitor.testimonial) {
        const isTestifying = this.props.visitor.testifying === entry.wallet
        const title = entry.hasTestified ? t('TESTIFIED') : isTestifying ? t('TESTIFYING') : t('TESTIFY')
        const disabled = !entry.momentOfDeath > 0 || entry.hasTestified || isTestifying
        let button
        if (entry.hasTestified || isTestifying) {
          button = (
            <OrangeButton disabled={true}>
              {title}
            </OrangeButton>
          )
        } else {
          button = (
            <WhiteButton disabled={disabled} onClick={() => {
              this.testify(entry)
            }}>
              {title}
            </WhiteButton>
          )
        }
        let displayName = entry.wallet
        if (this.props.relatedUsers[entry.wallet]) {
          displayName = this.props.relatedUsers[entry.wallet].name
        }
        rows.push([
          { id: 0, value: displayName },
          { id: 1, value: entry.actualConfirmations },
          { id: 2, value: entry.requiredConfirmations },
          { id: 3, component: button }
        ])
      }
    }
    return rows
  }

  render() {

    return (
      <NamespacesConsumer ns={['home', 'common']}>
        {t => {
          const legacyColumns = [
            { name: t('WALLET'), alignment: 'left', style: { minWidth: '300px' } },
            { name: t('LEGACY'), alignment: 'center' },
            { name: t('DEATH_MOMENT'), alignment: 'center' },
            { name: t('GRACE_PERIOD'), alignment: 'center' },
            { name: t('CLAIM'), alignment: 'center' }
          ]
          const testimnoalColumns = [
            { name: t('WALLET'), alignment: 'left' },
            { name: t('TESTIMONIALS'), alignment: 'center' },
            { name: t('REQUIRED_TESTIMONIALS'), alignment: 'center' },
            { name: t('TESTIFY'), alignment: 'center' }
          ]
          return (
            <div className={style.wrapper}>
              <Table title={t('LEGACY')}
                loading={this.props.visitor.fetchingLegacy}
                columns={legacyColumns}
                rows={this.buildLegacyRows(t)} />
              <Table
                title={t('TESTIMONIALS')}
                loading={this.props.visitor.fetchingTestimonial}
                columns={testimnoalColumns}
                rows={this.buildTestimonialRows(t)} />
            </div>

          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({
  relatedUsers: state.user.relatedUsers,
  visitor: state.visitor
}))(Home)
