import React from 'react'
import style from './Footer.scss'
import { NamespacesConsumer } from 'react-i18next'

export default ({front}) => (
  <NamespacesConsumer ns={['layout']}>
    {t => (
      <div className={style.wrapper}>
        <div className={style.left}> {t('ALL_RIGHTS_RESERVED',{rights: front.rights})}</div>
        <div className={style.right}>{t('CONTACT', {phone: front.phone})}</div>
      </div>

    )}

  </NamespacesConsumer>
)
