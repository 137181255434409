import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import heirsFetchDone from 'actions/heirs/heirsFetchDone'
import requestHeirs from 'actions/heirs/requestHeirs'
import deleteHeirDone from 'actions/heirs/deleteHeirDone'
import { actions as toastrActions } from 'react-redux-toastr'

export default ({ drizzle,firebase, i18n }) => {
  function * fetchHeirs () {
    try {
      const heirCount = yield call(drizzle.contracts.Wallet.methods.getHeirCount().call)
      const heirs = []
      const initialHeir = yield call(drizzle.contracts.Wallet.methods.initialHeir().call)
      for (let i = 0; i < heirCount; i++) {
        const heir = yield call(drizzle.contracts.Wallet.methods.getHeir(i).call)
          heirs.push({
            address: heir[0],
            legacy: heir[1],
            claimed: heir[2]
          })
      }
      yield put(heirsFetchDone(heirs,initialHeir))
    } catch (error) {
      yield put(heirsFetchDone(null))
      console.log(error)
    }
  }

  function * addHeir (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      if (action.heir.email) {
        firebase.firestore()
          .collection('invites')
          .doc(drizzle.contracts.Wallet.address)
          .collection('accounts')
          .doc(action.heir.account)
          .set({ email: action.heir.email })
      }
      yield call(drizzle.contracts.Wallet.methods.newHeir(action.heir.account, action.heir.legacy).send)
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('heirs:HEIR_ADDED_SUCCESSFULLY_TITLE'),
        message: i18n.t('heirs:HEIR_ADDED_SUCCESSFULLY_MESSAGE')
      }))
      yield put(requestHeirs(drizzle))
    } catch (error) {
      console.log(error)
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('heirs:HEIR_ADDED_WITHERROR_TITLE'),
        message: i18n.t('heirs:HEIR_ADDED_WITHERROR_MESSAGE')
      }))
    }
  }

  function * deleteHeir (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      yield call(drizzle.contracts.Wallet.methods.deleteHeir(action.account).send)
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('heirs:HEIR_DELETED_SUCCESSFULLY_TITLE'),
        message:i18n.t('heirs:HEIR_DELETED_SUCCESSFULLY_MESSAGE'),
      }))
      yield put(deleteHeirDone(action.account))
      yield put(requestHeirs(drizzle))
    } catch (error) {
      console.log(error)
      yield put(deleteHeirDone(action.account))
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('heirs:HEIR_DELETED_WITHERROR_TITLE'),
        message:i18n.t('heirs:HEIR_DELETED_WITHERROR_MESSAGE'),
      }))
    }
  }
  return function * watch () {
    yield takeLatest('REQUEST_HEIRS', fetchHeirs)
    yield takeEvery('ADD_HEIR', addHeir)
    yield takeEvery('DELETE_HEIR', deleteHeir)
  }
}
