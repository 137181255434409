import { combineReducers } from 'redux'
import { drizzleReducers } from 'drizzle'
import walletReducer from './walletReducer'
import visitorReducer from './visitorReducer'
import heirsReducer from './heirsReducer'
import witnessesReducer from './witnessesReducer'
import userReducer from './userReducer'
import { reducer as toastrReducer } from 'react-redux-toastr'

export default combineReducers({
  ...drizzleReducers,
  toastr: toastrReducer,
  wallet: walletReducer,
  visitor: visitorReducer,
  heirs: heirsReducer,
  witnesses: witnessesReducer,
  user: userReducer
})
