import React from 'react'

import style from './Modal.scss'

export default ({ title, shouldShow, closeAction, children }) => {
  if (shouldShow) {
    return (
      <div className={style.wrapper}>
        <div className={style.body}>
          <div className={style.title}> {title} </div>
          <div className={style['close-button']} onClick={closeAction}>x</div>
          <div className={style.content}>
            {children}
          </div>
        </div>
      </div>
    )
  } else return ''
}
