import { call, put, takeLatest } from 'redux-saga/effects'
import getWalletContract from 'services/getWalletContract'
import legacyFetchDone from 'actions/visitor/legacyFetchDone'
import testimonialFetchDone from 'actions/visitor/testimonialFetchDone'
import testifyDone from 'actions/visitor/testifyDone'
import claimDone from 'actions/visitor/claimDone'
import requestTestimonial from 'actions/visitor/requestTestimonial'
import requestLegacy from 'actions/visitor/requestLegacy'
import { actions as toastrActions } from 'react-redux-toastr'

export default ({ drizzle, i18n }) => {
  function * getLegacy () {
    const indexMethods = drizzle.contracts.Index.methods
    try {
      const legacyCount = yield call(indexMethods.getLegacyCount().call)
      const legacy = []
      for (let i = 0; i < legacyCount; i++) {
        const legacyEntry = yield call(indexMethods.getLegacy(i).call)
        const heirDetails = yield call(drizzle.contracts.WalletProxy.methods.heirDetails(legacyEntry[0]).call)
        let legacyAmount
        if (heirDetails.momentOfDeath > 0) {
          legacyAmount = legacyEntry[1] * heirDetails.balanceAtMomentOfDeath / 100
        } else {
          legacyAmount = legacyEntry[1] * heirDetails.balance / 100
        }
        legacy.push({
          wallet: legacyEntry[0],
          legacy: legacyAmount,
          claimable: heirDetails.canClaimLegacy,
          hasClaimed: heirDetails.hasClaimed,
          momentOfDeath: heirDetails.momentOfDeath,
          gracePeriod: heirDetails.gracePeriod
        })
      }
      yield put(legacyFetchDone(legacy))
    } catch (e) {
      yield put(legacyFetchDone(null))
      console.log(e)
    }
  }

  function * getTestimonial () {
    const indexMethods = drizzle.contracts.Index.methods
    try {
      const testimonialCount = yield call(indexMethods.getTestimonialCount().call)
      const testimonial = []
      for (let i = 0; i < testimonialCount; i++) {
        const testimonialEntry = yield call(indexMethods.getTestimonial(i).call)
        const witnessDetails = yield call(drizzle.contracts.WalletProxy.methods.witnessDetails(testimonialEntry).call)
        testimonial.push({
          wallet: testimonialEntry,
          actualConfirmations: witnessDetails.actualConfirmations,
          requiredConfirmations: witnessDetails.requiredConfirmations,
          momentOfDeath: witnessDetails.momentOfDeath,
          hasTestified: witnessDetails.didCallerTestify
        })
      }
      yield put(testimonialFetchDone(testimonial))
    } catch (e) {
      yield put(testimonialFetchDone(null))
      console.log(e)
    }
  }

  function * testify (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      const wallet = getWalletContract(drizzle, action.wallet)
      yield call(wallet.methods.addTestimonial().send)
      yield put(testifyDone())
      yield put(requestTestimonial(drizzle))
      yield put(requestLegacy(drizzle))
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('home:TESTIFIED_SUCCESSFULLY_TITLE'),
        message: i18n.t('home:TESTIFIED_SUCCESSFULLY_MESSAGE')
      }))
    } catch (e) {
      yield put(testifyDone())
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('home:TESTIFIED_WITHERROR_TITLE'),
        message: i18n.t('home:TESTIFIED_WITHERROR_MESSAGE')
      }))
      console.log(e)
    }
  }

  function * claim (action) {
    try {
      yield put(toastrActions.add({
        type: 'info',
        title: i18n.t('common:TRANSACTION_REQUESTED_TITLE'),
        message: i18n.t('common:TRANSACTION_REQUESTED_MESSAGE')
      }))
      const wallet = getWalletContract(drizzle, action.wallet)
      yield call(wallet.methods.claimLegacy().send)
      yield put(claimDone())
      yield put(requestLegacy(drizzle))
      yield put(toastrActions.add({
        type: 'success',
        title: i18n.t('home:CLAIMED_SUCCESSFULLY_TITLE'),
        message: i18n.t('home:CLAIMED_SUCCESSFULLY_MESSAGE')
      }))
    } catch (e) {
      yield put(claimDone())
      yield put(toastrActions.add({
        type: 'error',
        title: i18n.t('home:CLAIMED_WITHERROR_TITLE'),
        message: i18n.t('home:CLAIMED_WITHERROR_MESSAGE')
      }))
      console.log(e)
    }
  }
  return function * watch () {
    yield takeLatest('REQUEST_LEGACY', getLegacy)
    yield takeLatest('REQUEST_TESTIMONIAL', getTestimonial)
    yield takeLatest('REQUEST_TESTIFY', testify)
    yield takeLatest('REQUEST_CLAIM', claim)
  }
}
