import React from 'react'
import style from './Form.scss'
import BlueButton from 'components/stateless/buttons/BlueButton'

class Form extends React.Component {
  constructor({ initialValues, fields }) {
    super()
    const data = {}
    for (const key in fields) {
      data[key] = ''
    }
    for (const key in initialValues) {
      data[key] = initialValues[key]
    }
    this.state = { data }
  }

  componentDidMount() {
    const data = {}
    for (const key in this.props.initialValues) {
      data[key] = this.props.initialValues[key]
    }
    this.setState({ data })
  }

  setData = (e) => {
    const data = this.state.data
    data[e.target.name] = e.target.value
    this.setState({ data })
  }

  render() {
    const { fields, actionText, shouldShow, title, action, closeAction } = this.props
    if (shouldShow) {
      return (
        <div className={style.wrapper}>
          <div className={style.header}>
            <div className={style.title}> {title} </div>
            <div className={style['close-button']} onClick={closeAction}>x</div>
          </div>
          <div className={style.content}>
            {Object.keys(fields).map(key => (
              <div className={style['form-group']} key={key}>
                <label htmlFor={key}> {fields[key].name} </label>
                <div className={style.input}>
                  <input name={key} id={key} value={this.state.data[key]} onChange={this.setData} type={fields[key].type} />
                  <span> {fields[key].suffix} </span>
                </div>
              </div>
            ))}
            <div className={style['button-wrapper']}>
              <BlueButton className={style.button} onClick={() => action(this.state.data)}> {actionText} </BlueButton>
            </div>
          </div>
        </div>
      )
    } else return ''
  }
}
export default Form
