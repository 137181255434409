(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("deepmerge"), require("eth-block-tracker-es5"), require("is-plain-object"), require("redux"), require("redux-saga"), require("web3"));
	else if(typeof define === 'function' && define.amd)
		define(["deepmerge", "eth-block-tracker-es5", "is-plain-object", "redux", "redux-saga", "web3"], factory);
	else if(typeof exports === 'object')
		exports["drizzle"] = factory(require("deepmerge"), require("eth-block-tracker-es5"), require("is-plain-object"), require("redux"), require("redux-saga"), require("web3"));
	else
		root["drizzle"] = factory(root["deepmerge"], root["eth-block-tracker-es5"], root["is-plain-object"], root["redux"], root["redux-saga"], root["web3"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_deepmerge__, __WEBPACK_EXTERNAL_MODULE_eth_block_tracker__, __WEBPACK_EXTERNAL_MODULE_is_plain_object__, __WEBPACK_EXTERNAL_MODULE_redux__, __WEBPACK_EXTERNAL_MODULE_redux_saga__, __WEBPACK_EXTERNAL_MODULE_web3__) {
return 