import React from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { DrizzleContext } from 'drizzle-react'
import { Drizzle, generateContractsInitialState } from 'drizzle'
import firebase from 'firebase'
import { FirebaseProvider } from 'services/Context'
import './App.scss'
import Index from '/../build/contracts/Index.json'
import WalletProxy from '/../build/contracts/WalletProxy.json'
import reducer from 'reducers/rootReducer'
import saga from 'sagas/rootSaga'
import ReduxToastr from 'react-redux-toastr'
import DrizzleConsumer from '/components/loader/DrizzleConsumer'
import Welcome from '/components/welcome/Welcome'
import i18n from '../i18n'
import { I18nextProvider } from 'react-i18next'

const drizzleOptions = {
  contracts: [
    Index, WalletProxy
  ],
  polls: {
    blocks: 3000
  },
  syncAlways: true
}

const initialState = {
  contracts: generateContractsInitialState(drizzleOptions)
}

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)


var config = {
  apiKey: 'AIzaSyAgF1Ray5S4dp0srBQns5Q9eam0mQxFLHU',
  authDomain: 'bcripwallet.firebaseapp.com',
  databaseURL: 'https://bcripwallet.firebaseio.com',
  projectId: 'bcripwallet',
  storageBucket: 'bcripwallet.appspot.com',
  messagingSenderId: '123108889347'
}
firebase.initializeApp(config)

const App = ({ dapp }) => {
  if (dapp) {
    const drizzle = new Drizzle(drizzleOptions, store)
    sagaMiddleware.run(saga({ drizzle, firebase, i18n }))
    return (
      <Provider store={store}>
        <FirebaseProvider value={firebase}>
          <React.Fragment>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={false}
              position='bottom-right'
              transitionIn='fadeIn'
              transitionOut='fadeOut'
              closeOnToastrClick />
            <I18nextProvider i18n={i18n}>
              <DrizzleContext.Provider drizzle={drizzle}>
                <DrizzleContext.Consumer>
                  {drizzleContext => {
                    return (<DrizzleConsumer drizzleContext={drizzleContext} />)
                  }}
                </DrizzleContext.Consumer>
              </DrizzleContext.Provider>
            </I18nextProvider>
          </React.Fragment>
        </FirebaseProvider>
      </Provider>
    )
  } else {
    return (
      <Provider store={store}>
        <FirebaseProvider value={firebase}>
          <I18nextProvider i18n={i18n}>
            <Welcome />
          </I18nextProvider>
        </FirebaseProvider>
      </Provider>
    )
  }
}

export default App
