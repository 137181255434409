import { all, fork } from 'redux-saga/effects'
import { drizzleSagas } from 'drizzle'
import initializeWalletSaga from './initializeWalletSaga'
import visitorSaga from './visitorSaga'
import walletSaga from './walletSaga'
import heirsSaga from './heirsSaga'
import witnessesSaga from './witnessesSaga'

export default ({ drizzle,firebase, i18n }) => function* root() {
  yield all([
    fork(initializeWalletSaga({ drizzle, firebase, i18n })),
    fork(visitorSaga({ drizzle, firebase, i18n })),
    fork(walletSaga({ drizzle, firebase, i18n })),
    fork(heirsSaga({ drizzle, firebase, i18n })),
    fork(witnessesSaga({ drizzle, firebase, i18n })),
    ...drizzleSagas.map(saga => fork(saga))
  ])
}
