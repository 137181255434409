import React, { Component } from 'react'
import LoadDrizzle from '/components/loader/LoadDrizzle'

export default class DrizzleConsumer extends Component {
  shouldComponentUpdate (nextProps, nextState) {
    const oldContext = this.props.drizzleContext
    const newContext = nextProps.drizzleContext
    const shouldUpdate = (
      newContext.drizzle !== oldContext.drizzle ||
      newContext.initialized !== oldContext.initialized
    )
    return shouldUpdate
  }

  render () {
    return (
      <LoadDrizzle drizzleContext={this.props.drizzleContext} />
    )
  }
}
