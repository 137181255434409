import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import style from './Navbar.scss'
import hamburgerImg from '/../assets/imgs/hamburger.png'
import logoSmall from '/../assets/imgs/logo-small.png'
import newWallet from 'actions/wallet/newWallet'
import WalletBalance from './WalletBalance'
import { withDrizzle } from 'services/Context'
import { NamespacesConsumer } from 'react-i18next'

class Navbar extends Component {
  constructor(props, context) {
    super()
    this.dispatch = props.dispatch
    this.state = {
      menuOpened: false
    }
  }

  toggleMenu = () => {
    this.setState(prevState => {
      return { menuOpened: !prevState.menuOpened }
    })
  }

  createWallet = () => {
    this.dispatch(newWallet(this.props.drizzle))
  }

  render() {
    return (
      <NamespacesConsumer ns={['layout']}>
        {t => {
          const hasWallet = this.props.wallet.address > 0
          let walletButton
          if (this.props.wallet.creating) {
            walletButton = (<button disabled><img role='presentation' src={logoSmall} />{t('CREATING_WALLET')}</button>)
          } else if (hasWallet) {
            walletButton = (
              <WalletBalance />
            )
          } else {
            walletButton = (<button onClick={this.createWallet}><img role='presentation' src={logoSmall} />{t('CREATE_WALLET')}</button>)
          }
          return (
            <div>
              <div className={style['navbar-wrapper']}>
                <img role='presentation' className={style['menu-icon']} src={hamburgerImg} onClick={this.toggleMenu} />
                <div className={style['buttons-wrapper']}>
                  <NavLink exact to='/' activeClassName={style['selected']}>{t('VISITOR')}</NavLink>
                  <NavLink exact to='/wallet' activeClassName={style['selected']} disabled={!hasWallet}>{t('MY_WALLET')}</NavLink>
                  <NavLink exact to='/heirs' activeClassName={style['selected']} disabled={!hasWallet}>{t('HEIRS')}</NavLink>
                  <NavLink exact to='/witnesses' activeClassName={style['selected']} disabled={!hasWallet}>{t('WITNESSES')}</NavLink>
                </div>
                <div className={style['right-wrapper']}>
                  {walletButton}
                </div>
              </div>
              <div className={`${style['side-menu']} ${(this.state.menuOpened ? style['opened'] : '')}`}>
                <NavLink exact onClick={this.toggleMenu} to='/' activeClassName={style['selected']}>{t('VISITOR')}</NavLink>
                <NavLink exact onClick={this.toggleMenu} to='/wallet' activeClassName={style['selected']} disabled={!hasWallet}>{t('MY_WALLET')}</NavLink>
                <NavLink exact onClick={this.toggleMenu} to='/witnesses' activeClassName={style['selected']} disabled={!hasWallet}>{t('HEIRS')}</NavLink>
                <NavLink exact onClick={this.toggleMenu} to='/heirs' activeClassName={style['selected']} disabled={!hasWallet}>{t('WITNESSES')}</NavLink>
              </div>
            </div>

          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default withRouter(connect(state => ({
  wallet: state.wallet,
  walletContractStore: state.contracts.Wallet
}))(withDrizzle(Navbar)))
