import React, { Component } from 'react'
import style from './Heirs.scss'
import { connect } from 'react-redux'
import requestHeirs from 'actions/heirs/requestHeirs'
import addHeir from 'actions/heirs/addHeir'
import deleteHeir from 'actions/heirs/deleteHeir'
import Table from 'components/stateless/tables/Table'
import Form from 'components/stateless/forms/Form'
import WhiteButton from 'components/stateless/buttons/WhiteButton'
import OrangeButton from 'components/stateless/buttons/OrangeButton'
import BlueButton from 'components/stateless/buttons/BlueButton'
import { NamespacesConsumer } from 'react-i18next'
import { withDrizzle } from 'services/Context'

class Heirs extends Component {
  constructor(props) {
    super(props)
    this.dispatch = props.dispatch
    this.state = { showAddForm: false }
  }

  componentDidMount() {
    this.dispatch(requestHeirs())
  }

  toggleAddForm = () => {
    this.setState(prevState => ({ showAddForm: !prevState.showAddForm }))
  }

  buildRows = (t) => {
    const rows = []
    if (this.props.heirs.entries) {
      let i = 0
      for (const heir of this.props.heirs.entries) {
        const deleting = this.props.heirs.deleting.filter(account => account === heir.address).length > 0
        let button = (<div></div>)
        if (deleting) {
          button = (
            <OrangeButton disabled={true}>
              {t('common:DELETING')}
            </OrangeButton>
          )
        } else if (i !== 0) {
          button = (
            <WhiteButton onClick={() => {
              this.deleteHeir(heir)
            }}>
              {t('common:DELETE')}
            </WhiteButton>
          )
        }
        let displayName = heir.address
        if (i === 0) {
          displayName = 'RipWallet'
        } else if (this.props.relatedUsers[heir.address]) {
          displayName = this.props.relatedUsers[heir.address].name
        }
        rows.push([
          { id: 0, value: displayName },
          { id: 1, value: `${heir.legacy} %` },
          { id: 2, value: heir.claimed ? t('common:YES') : t('common:NO') },
          {
            id: 3,
            component: button
          }
        ])
        i++
      }
    }
    return rows
  }

  deleteHeir = (heir) => {
    this.dispatch(deleteHeir(heir.address))
  }

  addHeir = (heir) => {
    this.dispatch(addHeir(heir.account, heir.legacy, heir.email))
    this.toggleAddForm()
  }

  render() {
    return (
      <NamespacesConsumer ns={['heirs', 'common']}>
        {t => {
          const addFields = {
            account: {
              name: t('HEIR_ADDRESS'),
              type: 'text'
            },
            legacy: {
              name: t('LEGACY'),
              type: 'number',
              suffix: '%'
            },
            email: {
              name: t('common:EMAIL'),
              type: 'text'
            }
          }
          const columns = [
            { name: t('ACCOUNT'), alignment: 'left', style: { minWidth: '235px', maxWidth: '285px' } },
            { name: t('LEGACY').toUpperCase(), alignment: 'center' },
            { name: t('CLAIMED'), alignment: 'center' },
            { name: t('common:DELETE'), alignment: 'center' }
          ]
          return (
            <div className={style.wrapper}>
              <Table title={t('HEIRS')} loading={this.props.heirs.fetching} columns={columns} rows={this.buildRows(t)} >
                <BlueButton className={style.add} onClick={this.toggleAddForm}> {t('common:ADD')} </BlueButton>
              </Table>
              <Form
                title={t('NEW_HEIR')}
                shouldShow={this.state.showAddForm}
                fields={addFields}
                actionText={t('common:SUBMIT')}
                action={this.addHeir}
                closeAction={this.toggleAddForm}
              />
            </div>

          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({ relatedUsers: state.user.relatedUsers, heirs: state.heirs }))(withDrizzle(Heirs))
