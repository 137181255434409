import React, { Component } from 'react'
import { connect } from 'react-redux'

import style from './Loader.scss'
import loadingImg from '/../assets/imgs/loading-dapp.png'

import LoadWallet from 'components/loader/LoadWallet'
import { DrizzleProvider } from 'services/Context'
import { NamespacesConsumer } from 'react-i18next'

class LoadDrizzle extends Component {
  constructor({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  render() {
    return (
      <NamespacesConsumer ns={['layout']}>
        {t => {
          let message = null
          if (this.props.web3.status === 'failed') {
            message = t('DAPP_ERROR')
          } else if (Object.keys(this.props.accounts).length === 0) {
            message = t('LOADING_DAPP')
          } else if (this.props.drizzleStatus.initialized) {
            return (
              <DrizzleProvider value={this.props.drizzleContext.drizzle}>
                <LoadWallet />
              </DrizzleProvider>
            )
          }
          return (
            <div className={style.wrapper}>
              <div className={style.container}>
                <img src={loadingImg} />
                <span>{message}</span>
              </div>
            </div>
          )
        }}
      </NamespacesConsumer>

    )
  }
}

export default connect(state => ({
  accounts: state.accounts,
  drizzleStatus: state.drizzleStatus,
  web3: state.web3
}))(LoadDrizzle)
