import React from 'react'
import style from './Table.scss'
import { NamespacesConsumer } from 'react-i18next'

export default ({ title, loading, rows, columns, children }) => {
  title = `${title} ${loading ? '🔄' : ''}`
  const tableEmpty = rows === null || rows.length === 0
  return (
    <NamespacesConsumer ns={['common']}>
      {t => (
        <div className={style['table-wrapper']}>
          <div className={style['main-title']}> {title} </div>
          {(() => {
            if (tableEmpty) {
              return (
                <div className={style['empty-table']}> {t('NO_CONTENT')} </div>
              )
            } else {
              return (
                <div className={style.table}>
                  <table>
                    <thead>
                      {
                        columns &&
                        <tr className={style.row + ' ' + style.header}>
                          {columns.map((column, index) => (
                            <th className={`${style.cell} ${style[column.alignment]}`} style={column.style} key={index}>
                              {column.name}
                            </th>
                          ))}
                        </tr>
                      }
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index} className={style.row}>
                          {row.map((cellData, index) => {
                            return (
                              <th className={`${style.cell} ${style[columns[index].alignment]}`} key={index} style={columns[index].style}>
                                {(() => {
                                  if (cellData.component) {
                                    return cellData.component
                                  } else {
                                    return cellData.value + (cellData.extra ? ' ' + cellData.extra : '')
                                  }
                                })()}
                              </th>
                            )
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }
          })()}
          {children}
        </div>
      )}
    </NamespacesConsumer>
  )
}
