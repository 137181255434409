import { utils } from 'web3'

function precisionRound (number, precision) {
  var factor = Math.pow(10, precision)
  return Math.round(number * factor) / factor
}

export function toEth (amountInWei) {
  return precisionRound(utils.fromWei(amountInWei.toString(), 'ether'), 8)
}

export function toWei (amountInEth) {
  return utils.toWei(amountInEth.toString(), 'ether')
}
