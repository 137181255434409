export default ({ firebase }) => {
  const width = 400
  const height = 600
  const left = window.screenX + (window.outerWidth - width) / 2
  const top = window.screenY + (window.outerHeight - height) / 2.5

  
  const loginWindow = window.open(
    '/login',
    '',
    `location=0,status=0,width=${width},height=${height},left=${left},top=${top}`
  )
  const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
    if (user !== null) {
      loginWindow.close()
      unregisterAuthObserver()
    }
  })

}