import React, { Component } from 'react'
import { connect } from 'react-redux'
import style from './RipModal.scss'
import cancelableRpImg from '/../assets/imgs/rip-cancelable.png'
import ripFinalImg from '/../assets/imgs/rip-final.png'
import resetRip from 'actions/wallet/resetRip'
import { NamespacesConsumer } from 'react-i18next'

class RipModal extends Component {
  constructor({ dispatch }) {
    super()
    this.dispatch = dispatch
  }

  resetRip = () => {
    this.dispatch(resetRip())
  }

  render() {
    const wallet = this.props.wallet

    if (wallet.momentOfDeath > 0) {
      const cancelableUntil = new Date((wallet.momentOfDeath + wallet.gracePeriod) * 1000)
      if (new Date().getTime() > cancelableUntil.getTime()) {
        return (
          <NamespacesConsumer ns={['wallet', 'common']}>
            {t => (
              <div className={style.wrapper}>
                <div className={style.body}>
                  <div className={style.content}>
                    <img src={ripFinalImg} />
                    <p>{t('COMPLETED_RIP')}</p>
                  </div>
                </div>
              </div>
            )}
          </NamespacesConsumer>
        )
      } else {
        return (
          <NamespacesConsumer ns={['wallet', 'common']}>
            {t => (
              <div className={style.wrapper}>
                <div className={style.body}>
                  <div className={style.content}>
                    <img src={cancelableRpImg} />
                    <p>
                      {t('CANCELABLE_RIP',{until: cancelableUntil.toLocaleDateString()})}
                    </p>
                    <button
                      onClick={this.resetRip}
                      disabled={wallet.resettingRip}>
                      {wallet.resettingRip ? t('common:CANCELING') : t('common:CANCEL')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </NamespacesConsumer>
        )
      }
    } else return ''
  }
}

export default connect(state => ({ wallet: state.wallet }))(RipModal)
