import React, { Component } from 'react'
import { connect } from 'react-redux'
import requestWitnesses from 'actions/witnesses/requestWitnesses'
import addWitness from 'actions/witnesses/addWitness'
import deleteWitness from 'actions/witnesses/deleteWitness'
import setRequiredConfirmations from 'actions/witnesses/setRequiredConfirmations'
import Form from 'components/stateless/forms/Form'
import Table from 'components/stateless/tables/Table'
import editImg from '/../assets/imgs/edit.svg'
import style from './Witnesses.scss'
import { withDrizzle } from 'services/Context'
import WhiteButton from 'components/stateless/buttons/WhiteButton'
import OrangeButton from 'components/stateless/buttons/OrangeButton'
import BlueButton from 'components/stateless/buttons/BlueButton'
import { NamespacesConsumer } from 'react-i18next'

class Witnesses extends Component {
  constructor(props) {
    super(props)
    this.requiredConfirmationsKey = this.props.drizzle.contracts.Wallet.methods.requiredConfirmations.cacheCall()
    this.dispatch = props.dispatch
    this.state = { showAddForm: false }
  }

  componentDidMount() {
    this.dispatch(requestWitnesses())
  }

  toggleAddForm = () => {
    this.setState(prevState => ({ showAddForm: !prevState.showAddForm }))
  }

  deleteWitness = (witness) => {
    this.dispatch(deleteWitness(witness.address))
  }

  addWitness = (witness) => {
    this.dispatch(addWitness(witness.account,witness.email))
    this.toggleAddForm()
  }

  toggleConfirmationsForm = () => {
    this.setState(prevState => ({ showConfirmationsForm: !prevState.showConfirmationsForm }))
  }

  setRequiredConfirmations = (data) => {
    this.dispatch(setRequiredConfirmations(data.confirmations))
    this.toggleConfirmationsForm()
  }

  buildRows = (t) => {
    const rows = []
    if (this.props.witnesses.entries) {
      for (const witness of this.props.witnesses.entries) {
        const deleting = this.props.witnesses.deleting.filter(account => account === witness.address).length > 0
        let button
        if (deleting) {
          button = (
            <OrangeButton disabled={true}>
              {t('common:DELETING')}
            </OrangeButton>
          )
        } else {
          button = (
            <WhiteButton onClick={() => {
              this.deleteWitness(witness)
            }}>
              {t('common:DELETE')}
          </WhiteButton>
          )
        }
        let displayName = witness.address
        if (this.props.relatedUsers[witness.address]) {
          displayName = this.props.relatedUsers[witness.address].name
        }
        rows.push([
          { id: 0, value: displayName },
          { id: 1, value: witness.confirmed ? t('common:YES') : t('common:NO') },
          { id: 2, component: button }
        ])
      }
    }
    return rows
  }

  render() {
    return (
      <NamespacesConsumer ns={['witnesses', 'common']}>
        {t => {
          const addFields = {
            account: {
              name: t('WITNESS_ADDRESS'),
              type: 'text'
            },
            email: {
              name: t('common:EMAIL'),
              type: 'text'
            }
          }
          const requiredConfirmationFields = {
            confirmations: {
              name: t('REQUIRED_CONFIRMATIONS'),
              type: 'number'
            }
          }
          const columns = [
            { name: t('WITNESS'), alignment: 'left', style: { minWidth: '235px', maxWidth: '285px' } },
            { name: t('CONFIRMED'), alignment: 'center' },
            { name: t('DELETE'), alignment: 'center' }
          ]
          let requiredConfirmations = '🔄'
          if (this.props.walletStore.synced) {
            if (this.props.walletStore.requiredConfirmations[this.requiredConfirmationsKey]) {
              requiredConfirmations = this.props.walletStore.requiredConfirmations[this.requiredConfirmationsKey].value
            }
          }
          return (
            <div className={style.wrapper}>
              <Table title={t('WITNESSES')} loading={this.props.witnesses.fetching} columns={columns} rows={this.buildRows(t)} >
                <div className={style.tableFooter}>
                  <BlueButton onClick={this.toggleAddForm}> {t('common:ADD')} </BlueButton>
                  <div className={style.edit}>
                    <span>{t('REQUIRED_CONFIRMATIONS')} {requiredConfirmations}</span>
                    <img onClick={this.toggleConfirmationsForm} src={editImg} />
                  </div>
                </div>
              </Table>
              <Form
                title={t('NEW_WITNESS')}
                shouldShow={this.state.showAddForm}
                fields={addFields}
                actionText={t('common:SUBMIT')}
                action={this.addWitness}
                closeAction={this.toggleAddForm}
              />
              {Number.isInteger(parseInt(requiredConfirmations)) &&
                <Form
                  title={t('REQUIRED_CONFIRMATIONS')}
                  shouldShow={this.state.showConfirmationsForm}
                  fields={requiredConfirmationFields}
                  initialValues={{
                    confirmations: requiredConfirmations
                  }}
                  actionText={t('common:SUBMIT')}
                  action={this.setRequiredConfirmations}
                  closeAction={this.toggleConfirmationsForm}
                />
              }
            </div>

          )
        }}
      </NamespacesConsumer>
    )
  }
}

export default connect(state => ({
  relatedUsers: state.user.relatedUsers,
  wallet: state.wallet,
  witnesses: state.witnesses,
  walletStore: state.contracts.Wallet
}))(withDrizzle(Witnesses))
